import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['full', 'partial']

  switchOption(event) {
    event.preventDefault()

    if(event.target.value == 'full') {
      this.partialTarget.classList.add('hidden')
      this.fullTarget.classList.remove('hidden')
    } else if(event.target.value == 'partial') {
      this.fullTarget.classList.add('hidden')
      this.partialTarget.classList.remove('hidden')
    }
  }
}
