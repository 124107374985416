import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ["order"];

  handleChange(event) {
    const selectedValue = event.target.value;
    const orderId = this.element.dataset.orderId;

    patch("/dashboard/orders/"+orderId+"/update_pre_collection_confirmation_state", {
      contentType: "application/json",
      responseKind: "json",
      body: JSON.stringify({ pre_collection_confirmation_state: selectedValue }),
    }).then(() => { location.reload(); });
  }

  changeStatusUsingTurboRequest(event) {
    const selectedValue = event.target.value;
    const orderId = this.element.dataset.orderId;

    if(selectedValue == "unconfirmed") { return; }

    patch("/dashboard/orders/"+orderId+"/update_pre_collection_confirmation_state", {
      method: "PATCH",
      contentType: "application/json",
      responseKind: "turbo-stream",
      body: JSON.stringify({ pre_collection_confirmation_state: selectedValue }),
    }).then(response => response.text()).then(html => Turbo.renderStreamMessage(html));
  }
}
