import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle"];

  hide() {
    this.toggleTargets.forEach((element) => {
      {
      element.classList.toggle("hidden");
      }
    });
  }
}
