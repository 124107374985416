import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  storedStripe = null
  storedCardElement = null

  static values = {
    stripe: String,
    secret: String,
  }

  static targets = ['form']

  connect() {
    const stripe = Stripe(this.stripeValue)
    const elements = stripe.elements({ clientSecret: this.secretValue })

    const styles = {
      base: {
        color: '#374151',
        fontWeight: '500',
        fontSize: '14px',
      },
      invalid: {
        color: '#B91C1C',
      }
    }

    const displayStripeValidationError = function(elementID, error) {
      let displayError = document.getElementById(elementID)
      if (error) {
        displayError.textContent = error.message
      } else {
        displayError.textContent = ''
      }
    }

    const cardNumberElement = elements.create('cardNumber', { style: styles, placeholder: '' })
    cardNumberElement.mount(`#${this.formTarget.id} .card-number`)
    cardNumberElement.on('change', ({error}) => displayStripeValidationError(`${this.formTarget.id}-card-number-error`, error))

    const cardExpiryElement = elements.create('cardExpiry', { style: styles, placeholder: '' })
    cardExpiryElement.mount(`#${this.formTarget.id} .card-expiry-date`)
    cardExpiryElement.on('change', ({error}) => displayStripeValidationError(`${this.formTarget.id}-card-expiry-date-error`, error))

    const cardCvcElement = elements.create('cardCvc', { style: styles, placeholder: '' })
    cardCvcElement.mount(`#${this.formTarget.id} .card-cvc`)
    cardCvcElement.on('change', ({error}) => displayStripeValidationError(`${this.formTarget.id}-card-cvc-error`, error))

    const postalCodeElement = elements.create('postalCode', { style: styles, placeholder: '' })
    postalCodeElement.mount(`#${this.formTarget.id} .card-postcode`)
    postalCodeElement.on('change', ({error}) => displayStripeValidationError(`${this.formTarget.id}-card-postcode-error`, error))

    this.storedStripe = stripe
    this.storedCardNumberElement = cardNumberElement
  }

  submit(event) {
    event.preventDefault()

    event.target.textContent = 'Saving..'
    this.storedStripe.handleCardSetup(
      this.secretValue,
      this.storedCardNumberElement,
    ).then(function (result) {
      if (result.error) {
        event.target.textContent = 'Save'
        document.querySelector(`#${this.formTarget.id}-card-errors`).textContent = result.error.message
      } else {
        document.querySelector(`#${this.formTarget.id} input[name='stripe_payment_method_id']`).value = result.setupIntent.payment_method
        this.formTarget.submit()
      }
    }.bind(this))
  }
}
