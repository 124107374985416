import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['hiddenMessage', 'requiredField'];

  displayAdditionalContent(event) {
    event.preventDefault()

    if(event.target.value == 'Other') {
      this.hiddenMessageTarget.classList.remove('hidden')
      this.requiredFieldTarget.classList.add('required-with-asterisk')
    } else {
      this.hiddenMessageTarget.classList.add('hidden')
      this.requiredFieldTarget.classList.remove('required-with-asterisk')
    }
  }
}
