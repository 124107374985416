import { Autocomplete } from 'stimulus-autocomplete'

export default class CompanyAutocomplete extends Autocomplete {
  static values = { dontTriggerTab: Boolean }
  static targets = ["name", "number", "postcode", "line", "town"];


  autocompleteFields({detail: {value}}) {
    const companyData = JSON.parse(value);
    this.nameTarget.value = companyData.name;
    this.numberTarget.value = companyData.number;
    if(this.hasPostcodeTarget && this.hasLineTarget && this.hasTownTarget) {
      this.postcodeTarget.value = companyData.address.postcode;
      this.lineTarget.value = companyData.address.line_1;
      this.townTarget.value = companyData.address.post_town;
    } else {
      if(this.dontTriggerTabValue){
        document.querySelector('[name="customer_company[trading_address_attributes][postcode]"]').value = companyData.address.postcode;
        document.querySelector('[name="customer_company[trading_address_attributes][line_1]"]').value = companyData.address.line_1;
        document.querySelector('[name="customer_company[trading_address_attributes][post_town]"]').value = companyData.address.post_town;
      } else{
        this.addTradingAddress();
        this.tradingAddressForm.querySelector('[name="customer_company[trading_address_attributes][postcode]"]').value = companyData.address.postcode;
        this.tradingAddressForm.querySelector('[name="customer_company[trading_address_attributes][line_1]"]').value = companyData.address.line_1;
        this.tradingAddressForm.querySelector('[name="customer_company[trading_address_attributes][post_town]"]').value = companyData.address.post_town;
      }
    }
  }

  addTradingAddress() {
    if (!this.tradingAddressForm)
      this.tradingAddressTab.querySelector('[data-action="nested-form#add"]').click();

    this.tradingAddressForm;
  }

  get tradingAddressTab() {
    return document.querySelector('[data-tab-target="tab"][tab-target-id="Trading"]');
  }

  get tradingAddressForm() {
    return this.tradingAddressTab.querySelector('.trading-address-form-wrapper');
  }

}
