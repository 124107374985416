import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ["hidden", "contact", "number"];

  run() {
    const jobsiteId = event.target.value;
    const customerCompanyId = this.hiddenTarget.dataset.jobsiteDetailsCcidValue;

    this.appendFields(jobsiteId, customerCompanyId);

    if (this.hiddenTarget.classList.contains('hidden')) {
      enter(this.hiddenTarget);
    }
  }

  appendFields(jobsiteId, customerCompanyId) {

    if(jobsiteId && customerCompanyId) {
      fetch('/dashboard/customer_companies/jobsite.json?jobsite_id='+jobsiteId+'&customer_company_id='+customerCompanyId)
        .then(response => response.json())
        .then(data => {
          this.contactTarget.value = data.contact_name;
          this.numberTarget.value = data.contact_mobile_phone;
         })
    }
  }
}
