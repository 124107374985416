import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static values = { closeOnSubmit: Boolean }
  static targets = ['modal']

  connect() {
    if(this.closeOnSubmitValue) {
      this.element.addEventListener('turbo:submit-end', (event) => {
          if (event.detail.success) {
              this.close();
          }
      });
    }
  }

  submit(event) {
    const form = event.target.closest('.hotwire-modal').getElementsByTagName("form")[0];
    event.preventDefault();
    if (form) {
        form.requestSubmit();
    }
  }

  open() {
    this.modalTarget.classList.remove('hidden')
    this.modalTarget.classList.add('flex')
  }

  close() {
    this.modalTarget.classList.add('hidden')
    this.modalTarget.classList.remove('flex')
  }
}
