import Flatpickr from 'stimulus-flatpickr'

// use with data-controller='custom-flatpickr'
// add 'required=true' to the text field, and if the user doesn't input a date it will cause a validation error
export default class extends Flatpickr {
  static values = { disableWeekends: Boolean }

  initialize() {
    super.initialize()
  }

  connect() {
    if (this.disableWeekendsValue) {
      this.config.disable = [
        function(date) {
          return (date.getDay() === 6 || date.getDay() === 0);
        }
      ]
    }
    super.connect()
  }

  disconnect() {
    super.disconnect()
  }

  open(_selectedDates, _dateStr, _instance) {
    this.fp.input.readOnly = true;
  }

  close(_selectedDates, _dateStr, _instance) {
    this.fp.input.readOnly = false;
    this.fp.input.blur();
  }
}
