import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["deliveryFee", "collectionFee", "unavailableInput"];

  toggleInputValidations(e) {
    if(this.unavailableInputTargets.every((e) => e.checked)) {
      this.deliveryFeeTarget.required = false;
      this.collectionFeeTarget.required = false;
    } else {
      this.deliveryFeeTarget.required = true;
      this.collectionFeeTarget.required = true;
    }
  }
}
