import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["freightType"]

  add() {
    if(this.freightTypeTargets.length > 1) {
      // only the second elemnt should be set to collection
      let el = this.freightTypeTargets[1];
      el.value = "collection"
    }
  }
}
