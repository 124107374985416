import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="highlight-selected"
export default class extends Controller {
  static targets = ['row', 'navButton']

  navHighlight(event) {
    this.highlightRow(this.navButtonTarget.dataset.rowIndex)
  }

  toggleHighlight(event) {
    this.highlightRow(this.rowTarget.dataset.rowIndex)
  }

  highlightRow(index) {
    const table = document.querySelector('table')
    const row = table.querySelector(`tr[data-row-index="${index}"]`)

    this.unhighlightAll();
    this.highlight(row);
  }

  unhighlightAll(){
    document.querySelectorAll('.highlighted')
      .forEach(row => this.unhighlight(row))
  }

  unhighlight(row){
    row.classList.remove('highlighted')
    row.classList.add('hover:bg-slate-100')
    row.classList.add('even:bg-gray-50')
  }

  highlight(row){
    row.classList.remove('hover:bg-slate-100')
    row.classList.remove('even:bg-gray-50')
    row.classList.add('highlighted')
  }
}
