import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['form']

  markAsPrimary(event) {
    this.formTarget.submit()
  }
}

