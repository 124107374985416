import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String , group: String}

  toggle(e) {
    let url = this.urlValue

    let formData = new FormData()
    formData.append(e.target.name, e.target.value)
    formData.append('_method', 'put')

    this.toggleElements(false)

    fetch(`${url}`, {
      body: formData,
      method: 'PUT',
      headers: {
        'X-CSRF-Token': this.getMetaValue('csrf-token'),
        'accept': 'application/json'
      },
    }).then((response) => {
      this.toggleElements(true)
    }
    )
  }

  toggleElements(enable = true){
    let elements = document.querySelectorAll(`[data-inline-radio-toggle-group-value="${this.groupValue}"]`)
    let len=elements.length
    for(var i=0;i<len;i++)
    {
       if(enable == true){
         elements[i].disabled=false
       }else{
         elements[i].disabled=true
      }
     }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute('content')
  }
}
