import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = ["hidden"]

  run() {
    if (this.hiddenTarget.classList.contains('hidden')) {
      enter(this.hiddenTarget)
    } else {
      leave(this.hiddenTarget)
    }
  }
}
