import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['message']

  dismiss(event) {
    event.preventDefault()

    this.messageTarget.remove()
  }
}
