import { Controller } from "@hotwired/stimulus";
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["form"];

  async submit() {
    const formData = new FormData(this.formTarget);
    formData.set("filters", this._isOpen());
    // FormData.delete() doesn't work so we create an empty FormData object
    const clean = new FormData();
    for (const [key, value] of formData) {
      if (!!value) {
        clean.set(key, value);
      }
    }

    const endpoint = window.location;
    endpoint.search = new URLSearchParams(clean);
    await get(endpoint);
  }

  toggleExpand() {
    if (this._isOpen()) {
      this.formTarget.setAttribute("aria-expanded", "");
    } else {
      this.formTarget.setAttribute("aria-expanded", true);
    }
  }

  _isOpen() {
    return this.formTarget.getAttribute("aria-expanded") === "true";
  }
}
