import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="table"
export default class extends Controller {
  static targets = ["emptyState"];
  connect() {
    // If table body has no rows, show empty state message
    // See app/views/dashboard/shared/table/_container.html.erb
    if (!this.element.querySelector("tbody>tr")) {
      this.emptyStateTarget.classList.remove("hidden");
      this.emptyStateTarget.classList.add("empty-table-state");
    }
  }
}
