import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select';
export default class extends Controller {
  static targets = ['triggerSelect', 'customerProfileId'];

  connect() {
    this.customerProfileIdSelect = new SlimSelect({
     select: this.customerProfileIdTarget,
   });

    const dropdownField = new SlimSelect({
      select: this.triggerSelectTarget,
      onChange: (value) => {
        this.appendFields(value);
      }
    });
  }

  appendFields(value) {
    const customerCompanyId = event.target.value;
    if(customerCompanyId) {
      fetch('/dashboard/customer_companies/user_by_company.json?customer_company_id='+customerCompanyId)
        .then(response => response.json())
        .then(data => {
          var dataArray = [];
          var dataArray = [{ text: '' , value: '' }];
          data.forEach(function (element) {
              dataArray.push({ text: element[1] , value: element[0].toString() });
           });
          this.customerProfileIdSelect.setData(dataArray);
         })
    }
  }
}
