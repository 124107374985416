import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  static targets = [
    "targetPriceField",
    "supplierUnitCostLabel",
    "customerUnitCostLabel",
    "customerUnitCostWeekDesc",
  ]

  connect() {
    const dropdownField = new SlimSelect({
      select: this.element,
      onChange: (value) => {
        this.appendFields(value)
      },
    })
  }

  appendFields(value) {
    const productId = event.target.value
    if (productId) {
      fetch(`/dashboard/products/${productId}/supplier_rate_summary.json`)
        .then((response) => response.json())
        .then((data) => {
          this.targetPriceFieldTarget.value = ""
          if (data.target_rate) {
            this.targetPriceFieldTarget.value = parseFloat(data.target_rate) / 100
          }
          if (data.flat_rate_hire == true) {
            this.supplierUnitCostLabelTarget.textContent = "Supplier unit cost"
            this.customerUnitCostLabelTarget.textContent = "Customer unit cost"
            if (this.hasCustomerUnitCostWeekDescTarget) {
              this.customerUnitCostWeekDescTarget.classList.add("hidden")
            }
          } else {
            this.supplierUnitCostLabelTarget.textContent = "Supplier unit cost per week"
            this.customerUnitCostLabelTarget.textContent = "Customer unit cost per week"
            if (this.hasCustomerUnitCostWeekDescTarget) {
              this.customerUnitCostWeekDescTarget.classList.remove("hidden")
            }
          }
        })
        .catch((error) => console.log(error))
    } else {
      this.targetPriceFieldTarget.value = ""
      this.supplierUnitCostLabelTarget.textContent = "Supplier unit cost per week"
      this.customerUnitCostLabelTarget.textContent = "Customer unit cost per week"
      if (this.hasCustomerUnitCostWeekDescTarget) {
        this.customerUnitCostWeekDescTarget.classList.remove("hidden")
      }
    }
  }
}
