import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-picker"
export default class extends Controller {
  static targets = ["input", "fileNameLabel"]
  _initialLabelText = ""

  updateLabelName() {
    if (this.inputTarget.files.length > 0) {
      this.fileNameLabelTarget.innerText = this.inputTarget.files[0].name;
    } else {
      this.fileNameLabelTarget.innerText = this._initialLabelText;
    }
  }
}
