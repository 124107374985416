/* eslint no-console:0 */

// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// To use tailwindcss add <%= stylesheet_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

"use strict";

import "@hotwired/turbo-rails";

import "ideal-postcodes-core/dist/ideal-postcodes-core.min.js"
import "ideal-postcodes-autocomplete/dist/ideal-postcodes-autocomplete.min.js"

import "mapkick/bundle"

import "./controllers";
