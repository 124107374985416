import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select';

export default class extends Controller {
  static targets = ['firstnameField', 'lastnameField', 'jobtitleField', 'mobilenumberField', 'officenumberField'];

  connect() {
    const dropdownField = new SlimSelect({
      select: this.element,
      onChange: (value) => {
        this.appendFields(value);
      }
    });
  }

  appendFields(value) {
    const userId = event.target.value;
    if(userId) {
      fetch(`/dashboard/users/${userId}/customer_profile.json`)
        .then(response => response.json())
        .then(data => {
          this.firstnameFieldTarget.value = data.first_name;
          this.lastnameFieldTarget.value = data.last_name;
          this.jobtitleFieldTarget.value = data.job_title;
          this.mobilenumberFieldTarget.value = data.mobile_number;
          this.officenumberFieldTarget.value = data.office_number;
          this.disableFields();
        })
        .catch(error => console.error(error));
      }
    else {
      this.enableFields();
      this.firstnameFieldTarget.value = '';
      this.lastnameFieldTarget.value = '';
      this.jobtitleFieldTarget.value = '';
      this.mobilenumberFieldTarget.value = '';
      this.officenumberFieldTarget.value = '';
    }
  }

  disableFields() {
    this.firstnameFieldTarget.disabled = true;
    this.lastnameFieldTarget.disabled = true;
  }

  enableFields() {
    this.firstnameFieldTarget.removeAttribute("disabled")
    this.lastnameFieldTarget.removeAttribute("disabled")
  }
}
