import {Controller} from '@hotwired/stimulus';
import * as env from 'env';

export default class extends Controller {
  static targets = ['postcode', 'line1', 'line2', 'line3', 'town', 'district', 'county', 'latitude', 'longitude']

  idealPostcodes = null

  // Usage:
  // Add controller and add data-address-autocomplete-target for each field that needs to be filled.
  // Use targets array for the full list of available fields. Besides postcode - all other fields are optional.
  // Caveat, the library expects each of the fields to have ID property set (not null or not undefined).
  connect() {
    if (!this.postcodeTarget ) { return }

    this.#initializeIdealPostcodes()

    if(this.idealPostcodes) {
      this.postcodeTarget.addEventListener(
        'focus',
        () => this.idealPostcodes.interface.onInput(),
        { once: true }
      )
    }
  }

  #initializeIdealPostcodes() {
    this.idealPostcodes = new IdealPostcodes.Autocomplete.Controller({
      api_key: env.IDEAL_POSTCODES_API_KEY,
      inputField: `#${this.postcodeTarget.id}`,
      outputFields: this.#getOutputFields()
    })
  }

  #getOutputFields() {
    let outputFields = {}
    outputFields.postcode = `#${this.postcodeTarget.id}`
    if(this.hasLine1Target) { outputFields.line_1 = `#${this.line1Target.id}` }
    if(this.hasLine2Target) { outputFields.line_2 = `#${this.line2Target.id}` }
    if(this.hasLine3Target) { outputFields.line_3 = `#${this.line3Target.id}` }
    if(this.hasTownTarget) { outputFields.post_town = `#${this.townTarget.id}` }
    if(this.hasDistrictTarget) { outputFields.district = `#${this.districtTarget.id}` }
    if(this.hasCountyTarget) { outputFields.county = `#${this.countyTarget.id}` }
    if(this.hasLatitudeTarget) { outputFields.latitude = `#${this.latitudeTarget.id}` }
    if(this.hasLongitudeTarget) { outputFields.longitude = `#${this.longitudeTarget.id}` }
    return outputFields
  }
}
