import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="optional-fields"
export default class extends Controller {
  static targets = [
    "field",
    "source"
  ]
  connect() {
    if(this.hasSourceTarget) {
      this.change({ target: this.sourceTarget })
    }
  }

  change({ target: selectedElement }) {
    const value = selectedElement.value;

    this.fieldTargets.forEach((field) => {
      if(field.hasAttribute('visible-unless')) {
        const visibleUnless = field.getAttribute('visible-unless');
        field.hidden = value == visibleUnless
      }

      if(field.getAttribute('visible-if')) {
        const visibleIf = field.getAttribute('visible-if');
        field.hidden = value != visibleIf
      }
    })
  }
}
