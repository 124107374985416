import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "checkbox", "hide"];
  static values = { disableFields: Boolean }

  connect() {
    if (this.hasSourceTarget) {
      this.toggle({ target: this.sourceTarget });
    }
  }

  hide({ target: selectedElement }) {
    this.checkboxTargets.forEach((element) => {
      if (this._elementIsVisible(selectedElement, element)) {
        element.toggleAttribute("disabled");
        if (element.childNodes.length > 0) {
          element.childNodes.forEach(function (item) {
            if (item.id) {
              item.toggleAttribute("disabled");
            }
          });
        }
      }
    });
  }

  toggle({ target: selectedElement }) {
    this.checkboxTargets.forEach((element) => {
      const inputs = element.querySelectorAll('input:not([type="checkbox"]), textarea, select');

      if (this._elementIsVisible(selectedElement, element)) {
        element.hidden = false;
        this._setInputsRequired(inputs);
      } else {
        element.hidden = true;
        this._setInputsRequired(inputs);
      }
    });
  }

  _elementIsVisible(selectedElement, element) {
    if (selectedElement instanceof HTMLSelectElement) {
      selectedElement = selectedElement.options[selectedElement.selectedIndex];
    }

    return element.getAttribute("checkbox-target-id") == selectedElement.getAttribute("checkbox-target-id");
  }

  _setInputsRequired(inputs) {
    inputs.forEach((input) => {
      if (this._elementIsRequired(input)) {
        input.required = true;
        if (this.disableFieldsValue) input.disabled = false;
      } else {
        input.required = false;
        if (this.disableFieldsValue) input.disabled = true;
      }
    });
  }

  _elementIsRequired(element) {
    // If the element is visible and element doesn't contain classname optional
    // The checkVisibility API isn't supported by many browser versions https://caniuse.com/?search=checkvisibility
    // So checking offsetWidth and offsetHeight gives the same behaviour
    let isVisibleInDOM = (element.offsetWidth > 0 || element.offsetHeight > 0)

    return isVisibleInDOM && !element.classList.contains('optional');
  }
}
