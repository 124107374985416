import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { destroy } from '@rails/request.js'

export default class extends Controller {
  static targets = ["action", "checkbox"]

  async deleteSelected({ params: { model } }) {
    const selectedIdsCount = this.selectedIds.length

    if (selectedIdsCount === 0) {
      alert("No items selected")
    } else if (confirm(`Are you sure you want to delete ${selectedIdsCount} items?`)) {
      await this._performDeleteRequest(model)
      this._reloadPage()
    }
  }

  updateSelected(e) {
    const form = e.target.closest('form')
    form.querySelector('#selected_ids').value = this.selectedIds
    form.submit()
  }

  toggleCheckbox() {
    if (this.selectedIds.length > 0) {
      this.enableBulkActions()
    } else {
      this.disableBulkActions()
    }
  }

  enableBulkActions() {
    this.actionTargets.forEach((e) => e.removeAttribute("disabled"))
  }

  disableBulkActions() {
    this.actionTargets.forEach((e) => e.setAttribute("disabled", true))
  }

  async _performDeleteRequest(model) {
    const ids = this.selectedIds
    if (!model) throw new Error("No model specified")

    await destroy(
      `/dashboard/bulk_delete`,
      {
        contentType: "application/json",
        body: JSON.stringify({ ids, model }),
      }
    )
  }

  _reloadPage() {
    Turbo.visit(window.location, { action: "replace" })
  }

  get selectedIds() {
    return this.checkboxTargets.filter((e) => e.checked).map((e) => e.value)
  }
}
