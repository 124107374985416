import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["tabButton", "tab", "tabOption"];
  static values = {
    visibleTab: String,
    defaultTab: String,
    shouldPersistState: { type: Boolean, default: false },
  };

  initialize() {
    this.visibleTabValue =
      new URLSearchParams(window.location.search).get("tab") ||
      this.defaultTabValue;
  }

  connect() {
    if(this.shouldPersistStateValue) {
      // Update visible tab from browser "back" history
      window.addEventListener("popstate", (e) => {
        this.visibleTabValue = e.state.tab || this.defaultTabValue;
        this._toggle_visible();
      });

      // Proxy the pushState function, to trigger updating the visible tab from browser "forward" history
      window.history.pushState = new Proxy(window.history.pushState, {
        apply: (target, thisArg, args) => {
          const newTab = args[0]?.tab;
          this.visibleTabValue = newTab || this.defaultTabValue;
          this._toggle_visible();
          return target.apply(thisArg, args);
        },
      });
    }
  }

  /**
   * Used to change visible tab, where navigation state is not persisted.
   * @param {Event} event
   */
  toggle(event) {
    event.preventDefault();
    this.visibleTabValue =
      event.currentTarget.value || event.currentTarget.getAttribute("tab-target-id");
    this._toggle_visible();
  }

  /**
   * pushState has the same effect as this.toggle,
   * Except that it updates the current window's navigation history.
   *
   * This means you can navigate back and forward using the native browser buttons.
   * A url search param "tab" is set to identify the visible tab.
   * @param {Event} event
   */
  pushState(event) {
    event.preventDefault();
    this.visibleTabValue =
      event.currentTarget.value || event.currentTarget.getAttribute("tab-target-id");

    const newUrl = new URL(window.location);
    newUrl.searchParams.set("tab", this.visibleTabValue);

    window.history.pushState(
      { tab: this.visibleTabValue },
      "",
      newUrl.toString()
    );
  }

  navigate(e) {
    Turbo.visit(e.currentTarget.value)
  }

  _toggle_visible() {
    this.tabTargets.forEach((element) => {
      element.hidden =
        element.getAttribute("tab-target-id") !== this.visibleTabValue;
    });
    this.tabButtonTargets.forEach((element) => {
      element.setAttribute(
        "aria-selected",
        element.getAttribute("tab-target-id") === this.visibleTabValue
      );
    });

    this.tabOptionTargets.forEach((element) => {
      element.selected = element.value === this.visibleTabValue;
    });
  }
}
