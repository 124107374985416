import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal-form-submit"
export default class extends Controller {
  static targets = ['error']
  connect(){
    console.log("form submit connected")
  }

  submit(event) {
    const form = event.target.closest("form");
    event.preventDefault();
    if (form) {
      if (form.checkValidity()){
        form.submit();
      } else {
        this.applyErrors(form)
      }

    }
  }

  applyErrors(form){
    const invalidFields = Array.from(form.querySelectorAll(":invalid"));
    invalidFields.forEach(field => {
      if(field.closest('label')){
        field.closest('label').classList.add("is-error");
      } else {
        field.classList.add("is-error");
      }
    });
  }
}
