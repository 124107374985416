import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["supplierPrice", "margin", "customerPrice"]

  connect() {
    if (this.supplierPriceTarget.value == this.customerPriceTarget.value) {
      // a margin has not been applied. apply default (17%)
      this.updateCustomerPrice()
    } else {
      // a margin has already been applied. update it.
      this.updateMargin()
    }
  }

  updateCustomerPrice() {
    const marginPercentValue = parseInt(this.marginTarget.value) || 0
    const customerPriceValue = this.supplierPriceValue / (1 - marginPercentValue / 100)
    this.customerPriceTarget.value = Math.ceil(customerPriceValue).toFixed(2)
  }

  updateMargin() {
    const customerPriceValue = parseFloat(this.customerPriceTarget.value) || 0
    const marginPercentValue = (customerPriceValue - this.supplierPriceValue) / customerPriceValue * 100
    this.marginTarget.value = marginPercentValue.toFixed()
  }

  get supplierPriceValue() {
    return parseFloat(this.supplierPriceTarget.value) || 0
  }
}
