import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slideout", "background"];

  toggle() {
    document.body.classList.toggle("overflow-hidden");
    this.slideoutTarget.classList.toggle("translate-x-full");
    this.backgroundTarget.classList.toggle("hidden");
    // if the slideout is being hidden, unhighlight all the rows
    if (this.backgroundTarget.classList.contains("hidden")) {
      this.dispatch("unhighlightAll");
    }
  }
}
