import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["addButton", "inputs"];

  validateFileUploadInputs(e) {
    if(e.target.value == "waste_transfer_note_plus_weighbridge_ticket") {
      if(this.inputsTargets.length > 1) {
        e.target.selectedIndex = 0;
        alert("We can only upload 'Waste Transfer Note + Weighbridge Ticket' as one document or 'Waste Transfer Note'" +
          "and 'Weighbridge Ticket' as two separate documents")
      } else {
        this.addButtonTarget.setAttribute('disabled', '');
      }

    } else {
      if(this.inputsTargets.length == 1) {
        this.addButtonTarget.removeAttribute("disabled");
      }
    }
  }

  validateIfRestrictionsCanBeRemoved(e) {
    this.addButtonTarget.removeAttribute("disabled");
  }

  validateIfMoreInputsAreAllowed(e) {
    if(this.inputsTargets.length > 1) {
      e.preventDefault()
      this.addButtonTarget.setAttribute('disabled', '');
    }
  }
}
