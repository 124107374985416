import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select';

// Connects to data-controller="prefix-match-slim-select"
export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
      settings: {
        allowDeselect: true
            },
      events: {
        searchFilter: (option, search) => {
          const optionText = option.text.toLowerCase();
          const searchLower = search.toLowerCase();

          // Use a regular expression to check if optionText starts with searchLower.
          const regex = new RegExp(`^${searchLower}`);

          return regex.test(optionText);
        }
      }
    });
  }
}
