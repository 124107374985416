import { ApplicationController, useDebounce } from 'stimulus-use'

export default class extends ApplicationController {
  static debounces = ['submit']
  static values = {
    wait: { type: Number, default: 500 }
  }

  connect() {
    useDebounce(this, { wait: this.waitValue })
  }

  submit(e) {
    const form = e.target.closest("form");
    form.requestSubmit();
  }
}
