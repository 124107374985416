import { Application } from "@hotwired/stimulus";

import NestedForm from "stimulus-rails-nested-form";
import CheckboxSelectAll from "stimulus-checkbox-select-all";
import Flatpickr from "stimulus-flatpickr";
import Reveal from "stimulus-reveal-controller";
import { Autocomplete } from 'stimulus-autocomplete'
import DropzoneController from '@kanety/stimulus-dropzone';
import { Turbo } from "@hotwired/turbo-rails";
import "chartkick/chart.js"

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
application.register("nested-form", NestedForm);
application.register("checkbox-select-all", CheckboxSelectAll);
application.register("flatpickr", Flatpickr);
application.register("reveal", Reveal);
application.register("autocomplete", Autocomplete);
application.register('dropzone', DropzoneController);

application.registerActionOption("disabled", ({ event: { target }, value }) => {
  const isDisabled = target.getAttribute("disabled") !== null;
  return isDisabled === value;
});

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
}
