import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['trigger', 'disable'];

    // If supplier checks all the 'No availability' options (triggers) from all the line items - we need to disable the fee inputs (disable)
    // and supplier unchecks at least one of those - it needs to go back to actve inputs
    greyOutFeeInputs(event) {
        event.preventDefault()
        if (this.triggerTargets.every(trigger => trigger.checked)) {
            this.disableTargets.forEach((element) => { element.setAttribute('disabled', '') })
        } else {
            this.disableTargets.forEach((element) => { element.removeAttribute("disabled"); })
        }
    }
}
